import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import ExpandableAlertList from '../../components/ExpandableAlertList/ExpandableAlertList';
import { Notifications, Settings } from '@mui/icons-material';
import './AlertsPage.scss';
import EventCountBar, { IEventCounter } from '../../components/EventCountBar/EventCountBarComponent';
import CustomDialog from '../../components/CustomDialog/CustomDialogComponent';
import useCustomDialogHandler from '../../components/CustomDialog/useCustomDialogHandler';
import FloorPlan from '../../components/FloorPlan/FloorPlanComponent';
import ZoomablePage from '../../components/zoomable/ZoomablePage';
import CameraView from '../../components/CameraView/CameraViewComponent';
import AlertBox from '../../components/AlertBox/AlertBoxComponent';
import SvgIcon from '../../components/SvgIcons/SvgIconComponent';
import alertService from '../../sdk/services/alertService';
import { scaleToPercentage } from '../../utils/common.util';
import { CameraDetailsInFloorMapType } from '../../sdk/types/cameraConfig.type';
import IncedentFeedAndLocation from '../../components/IncedentFeedAndLocation/IncedentFeedAndLocationComponent';
import { AlertDetailsType } from '../../sdk/types/alert.type';


const Expandableitems = [
    { id: 1, title: 'Item 1', content: <Notifications /> },
    { id: 2, title: 'Item 2', content: (<div><h4>Content for Item 2</h4><p>This content includes some static text and styles.</p></div>) },
    { id: 3, title: 'Item 3', content: (<div><button onClick={() => alert('Button Clicked!')}>Click Me</button></div>) },
    { id: 4, title: 'Item 4', content: (<div><button onClick={() => alert('Button Clicked!')}>Click Me</button></div>) },
    { id: 5, title: 'Item 5', content: (<div><h4>Content for Item 5</h4><p>This content includes some static text and styles.</p></div>) },
    { id: 6, title: 'Item 6', content: (<div><h4>Content for Item 6</h4><p>This content includes some static text and styles.</p></div>) },
    { id: 7, title: 'Item 7', content: (<div><button onClick={() => alert('Button Clicked!')}>Click Me</button></div>) },
    { id: 8, title: 'Item 8', content: <Notifications /> },
    { id: 9, title: 'Item 9', content: <Settings /> },
];

const eventCounterList: IEventCounter[] = [
    { count: 54, icon: 'safetyInfractions', name: 'Safety Infraction' },
    { count: 10, icon: 'ppeNoHardHat', name: 'PPE - No hard hat' },
    { count: 12, icon: 'ppeNoSafetyVest', name: 'PPE - No safty vest' },
    { count: 3, icon: 'ppeNoMask', name: 'PPE - No mask' },
    { count: 9, icon: 'fallDetection', name: 'Fall detection' },
    { count: 19, icon: 'proximityToRunningEquipment', name: 'Proximity to run equipment' },
];

const mockCameraList: CameraDetailsInFloorMapType[] = [
    {
        "cameraName": "Test camera",
        "cameraId": "Camera-01",
        "cameraFk": "6851039548714360a330850624d97198",
        "coordinateX": 0.4684492,
        "coordinateY": 0.26027623,
        "cameraAngle": 200,
        "fieldOfView": 200,
        "totalAlerts": 0
    },
    {
        "cameraName": "cam_3",
        "cameraId": "3",
        "cameraFk": "ced7ebddb66c4b70af3aad2a01605fcd",
        "coordinateX": 0.46434647,
        "coordinateY": 0.34575945,
        "cameraAngle": 0,
        "fieldOfView": 222,
        "totalAlerts": 0
    },
    {
        "cameraName": "cam_2",
        "cameraId": "2",
        "cameraFk": "cf60f9c44acd4188b0aa808c17f26a07",
        "coordinateX": 0.19098888,
        "coordinateY": 0.33189923,
        "cameraAngle": 0,
        "fieldOfView": 56,
        "totalAlerts": 0
    },
    {
        "cameraName": "cam_4",
        "cameraId": "4",
        "cameraFk": "ecdc4653677a4bd780f649ac6b9cda78",
        "coordinateX": 0.90109295,
        "coordinateY": 0.19329678,
        "cameraAngle": 180,
        "fieldOfView": 90,
        "totalAlerts": 0
    }
]

const AlertsPage: React.FC = () => {
    const { openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog } = useCustomDialogHandler();
    const [cameraListDetails, setCameraListDetails] = useState<CameraDetailsInFloorMapType[]>([]);
    const [selectedCamView, setSelectedCamView] = useState<string>();
    const [alertList, setAlertList] = useState<AlertDetailsType[]>([]);
    const [selectedAlert, setSelectedAlert] = useState<AlertDetailsType | null>(null);

    useEffect(() => {
        const handleGetFloorMapCameraDetails = async () => {
            const floorMapCameraList = await alertService.getAllFloorMapCameraDetails();
            setCameraListDetails(floorMapCameraList);
            const alertResponse = await alertService.getAllAlerts();
            setAlertList(alertResponse);
            console.log(floorMapCameraList, alertResponse);
        }

        handleGetFloorMapCameraDetails();
    }, []);

    const handleAlertViewDetailsClick = (selectedAlert: AlertDetailsType) => {
        handleOpenDialog();
    }

    const listContent = (
        <>
            <ExpandableAlertList items={alertList} onAlertViewDetailsClick={handleAlertViewDetailsClick} />
        </>
    );

    const dialogContent = (
        <section className="dialog-content">
            <aside className='side-content'>
                {
                    <ExpandableAlertList
                        items={alertList}
                        showHeader={false}
                        onAlertViewDetailsClick={handleAlertViewDetailsClick} />
                }
            </aside>
            <div className="main-content">
                <IncedentFeedAndLocation alertDetails={selectedAlert || {} as AlertDetailsType} />
            </div>
        </section>
    );

    const alertBoxRenderer = () => {
        return (
            <AlertBox
                headerLabel="Unattended"
                subHeaderLabel="Production zone"
                subHeaderIcon={<SvgIcon name='location' />}
                footerLabel="Cam ID - #2"
                timestamp="07-22-2024 | 12:09 AM"
                showActionSection={true}
                showViewDetailsBtn={true}
                thumbnails={{
                    image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg',
                    video: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg'
                }}
                onAlertViewDetailsClick={function (): void {
                    // throw new Error("Function not implemented.");
                }} />
        );
    }

    const alertBoxAndCameraViewRenderer = (id: string, alertCount: number = 0, cameraAngle: number = 0, fieldOfView: number = 0) => {
        return (
            <section className="alert-box-and-cam-view">
                {selectedCamView == id && alertBoxRenderer()}
                <CameraView
                    cameraAngle={cameraAngle}
                    fieldOfView={fieldOfView}
                    showPin={true}
                    pinTxt={`${alertCount}`}
                    onPinClick={() => {
                        setSelectedCamView(id);
                    }}
                />
            </section>
        );
    }

    const dynamicComponents = [
        {
            component: alertBoxAndCameraViewRenderer('1'),
            position: { x: '10%', y: '20%' },
            id: '1',
        },
        {
            component: alertBoxAndCameraViewRenderer('2'),
            position: { x: '30%', y: '50%' },
            id: '2'
        },
    ];

    const dynamicComponentList = useMemo(() => {
        return cameraListDetails.map((cam: CameraDetailsInFloorMapType, index: number) => ({
            component: alertBoxAndCameraViewRenderer((cam?.cameraId || index.toString()), cam?.totalAlerts, cam?.cameraAngle, cam?.fieldOfView),  // assuming cameraId is unique
            position: { x: scaleToPercentage(cam.coordinateX), y: scaleToPercentage(cam.coordinateY) },
            id: cam.cameraId,
        }));
    }, [cameraListDetails]);

    const handleComponentPositionChange = (position: { x: string; y: string }) => {
        console.log(`Updated/Created component position: x=${position.x}, y=${position.y}`);
    }

    const draggableCam = (<CameraView
        cameraAngle={100}
        fieldOfView={100}
        showPin={false}
        onPinClick={() => {
            console.log(this);
            // setSelectedCamView(id);
        }}
    />);

    const mainContent = (
        <div className='alert-main-content'>
            <EventCountBar countList={eventCounterList} />

            <CustomDialog
                headerLabel='Cam ID - Camera #1 - PPE - No Hard Hat'
                open={openDialog}
                content={dialogContent}
                dialogMaxWidth='lg'
                cancelText="Cancel"
                saveText="Save"
                onClose={handleCloseDialog}
                onCancel={handleCancelDialog}
                onSave={handleSaveDialog}
            />

            <br />

            {/* <FloorPlan showZoomAction={true} showFullScreenAction={true} /> */}
            <figure className="floormap-container">
                <ZoomablePage
                    // components={dynamicComponents}
                    components={dynamicComponentList}
                    showZoomControls={true}
                    isImageDraggable={true}
                    isCamCreationAllowed={false}
                // draggableComponent={draggableCam}
                // createAndDraggableComp={draggableCam}
                // onComponentPositionChange={handleComponentPositionChange} 
                />
            </figure>
        </div>
    );


    return (
        <section className='alerts-page'>
            <MainLayout listPosition="right" listContent={listContent} mainContent={mainContent} />

            {/* <ResponsiveDrawer listPosition='right' mainContent={mainContent} listContent={listContent} /> */}
        </section>
    );
};

export default AlertsPage;
