import RippleEffect from '../RippleEffect/RippleEffect';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import './CameraListComponent.scss';
import floorPlan from '../../assets/images/floor-plan.png';
import CustomDialog from '../CustomDialog/CustomDialogComponent';
import useCustomDialogHandler, { IDialogProps } from '../CustomDialog/useCustomDialogHandler';
import { useEffect, useRef, useState } from 'react';
import AddCameraDetails, { AddCameraDetailsHandle, FormFieldsType } from '../AddCameraDetails/AddCameraDetailsComponent';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import CustomButton from '../CustomButton/CustomButtonComponent';

// interface ICameraDetails {
//     id: number;
//     cameraName: string,
// }
interface ICameraList {
    list: CameraConfigType[];
    selectedCamera: CameraConfigType | null;
    updateSelectedCamera: (selected: CameraConfigType) => void;
    // addCamera: (cameraFormData: FormFieldsType) => void
    deleteCamera: (id: string) => void;
    onCameraAddedEvent: (data: CameraConfigType) => void;
}

const CameraList: React.FC<ICameraList> = ({ list, selectedCamera, updateSelectedCamera, deleteCamera, onCameraAddedEvent }) => {
    const { dialogData, openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog, setDialogData, handleSetDialogData } = useCustomDialogHandler();
    const [cameraToDelete, setCameraToDelete] = useState<CameraConfigType | null>(null);
    const [dialogProps, setDialogProps] = useState<IDialogProps | null>(null);
    const [addCameraFormData, setAddCameraFormData] = useState<FormFieldsType | null>(null);
    const [isAddCameraFormValid, setIsAddCameraFormValid] = useState<boolean>(false);
    const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);

    const addCameraDetailsRef = useRef<AddCameraDetailsHandle>(null);

    const handleListItemClick = (cam: CameraConfigType) => {
        updateSelectedCamera(cam);
    }

    useEffect(() => {
        addCameraDialogProps();
    }, [isAddCameraFormValid]);

    const handleListItemDeleteBtnClick = (camConfig: CameraConfigType) => {
        // setShowDeleteButton(true);
        setDialogProps({
            title: 'Delete camera',
            content: deleteCameraDialogContent,
            dialogMaxWidth: 'xs',
            cancelText: 'Cancel',
            saveText: 'Delete',
            isSaveDisabled: false,
            onSave: (data: any) => {
                if (camConfig?.id) {
                    deleteCamera(camConfig?.id);
                }
                handleDialogDeleteCameraClick();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        })
        setCameraToDelete(camConfig);
        handleOpenDialog();
    }

    const handleAddCameraBtnClick = () => {
        addCameraDialogProps();
        handleOpenDialog();
    }

    const addCameraDialogProps = () => {
        return setDialogProps({
            title: 'Add camera',
            content: addCameraDialogContent,
            dialogMaxWidth: 'lg',
            isSaveDisabled: !isAddCameraFormValid,
            cancelText: 'Cancel',
            saveText: 'Save',
            onSave: (data: any) => {
                console.log('addCameraData: ', data, addCameraFormData, this, addCameraDetailsRef.current);
                handleAddCamera();
                handleCloseDialog();
            },
            onCancel: () => {
                handleCloseDialog();
            }
        })
    }

    const handleAddCamera = () => {
        // if (addCameraFormData) {
        //     addCamera(addCameraFormData);
        // }
        if (addCameraDetailsRef.current) {
            addCameraDetailsRef.current.save();
        }
    }

    const handleDialogDeleteCameraClick = () => {
        setCameraToDelete(null);
        handleCloseDialog();
    }

    const deleteCameraDialogContent: JSX.Element = (
        <section className="delete-cam-dialog-content">
            <header>Are you sure you want to delete #{cameraToDelete?.cameraId} camera? </header>
            <p>Note: All the incidents associated with #{cameraToDelete?.cameraId} camera will get deleted</p>
        </section>
    );

    const handleAddCameraDetailsFormChange = (data: FormFieldsType) => {
        console.log('handleAddCameraDetailsFormChange::', data);
        setDialogData(data);
        setAddCameraFormData(data);
        handleSetDialogData(data);
    }

    const addCameraDialogContent: JSX.Element = (
        <AddCameraDetails
            ref={addCameraDetailsRef}
            onFormChange={handleAddCameraDetailsFormChange}
            onCameraAddedEvent={onCameraAddedEvent}
            formValidationResultEvent={(result: boolean) => {
                setIsAddCameraFormValid(result);
            }} />
    );

    const customDialogRenderer = () => {
        if (!dialogProps) return null;
        return (
            <CustomDialog
                headerLabel={dialogProps.title}
                open={openDialog}
                content={dialogProps.content}
                dialogMaxWidth={dialogProps?.dialogMaxWidth || 'md'}
                cancelText={dialogProps.cancelText}
                saveText={dialogProps.saveText}
                isSaveDisabled={dialogProps?.isSaveDisabled || false}
                onClose={handleCloseDialog}
                onCancel={dialogProps.onCancel}
                onSave={dialogProps.onSave}
            />
        )
    }

    return (
        <>
            <section className="camera-list-container">

                <header className='list-header'>

                    <div>
                        <h4>{list?.length}</h4>
                        <h4>Fixed camera</h4>
                    </div>

                    <aside className="actions">
                        <CustomButton icon={<SvgIcon name='plus' width={16} height={16} />} variant='contained' onClick={() => handleAddCameraBtnClick()} />
                        <CustomButton icon={<SvgIcon name='search' width={16} height={16} />} variant='contained' />
                    </aside>
                </header>

                <aside className="list-content">
                    {
                        list?.length ? (
                            <ul className="list">
                                {
                                    list.map((cam: CameraConfigType, index: number) => (
                                        <RippleEffect as="li" key={cam.id} className={`item ripple-list-item ${selectedCamera?.id === cam?.id ? 'selected' : ''}`} onClick={() => handleListItemClick(cam)}>
                                            <label title={cam.cameraId}>{cam.cameraName}</label>
                                            <CustomButton icon={<SvgIcon name='delete' width={16} height={16} />} variant='outlined' onClick={() => handleListItemDeleteBtnClick(cam)} />
                                        </RippleEffect>
                                    ))
                                }
                            </ul>
                        ) : (
                            <div className="no-list-items">
                                <p>No cameras to show.</p>
                                <RippleEffect as="div">
                                    <CustomButton text='Add camera' variant='contained' onClick={() => handleAddCameraBtnClick()} />
                                </RippleEffect>
                            </div>
                        )
                    }

                </aside>

            </section>
            {customDialogRenderer()}
        </>
    );
}

export default CameraList;