import { useState } from 'react';
import incidentImage from '../../assets/images/incident-image.png';
import { scaleToPercentage } from '../../utils/common.util';
import CustomButton from '../CustomButton/CustomButtonComponent';
import SurveillanceCamera from '../SurveillanceCamera/SurveillanceCameraComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import './IncedentFeedAndLocationComponent.scss';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { AlertDetailsType } from '../../sdk/types/alert.type';

interface IIncedentFeedAndLocation {
    alertDetails: AlertDetailsType
}

const IncedentFeedAndLocation: React.FC<IIncedentFeedAndLocation> = ({ alertDetails }) => {

    const [maxContainerId, setMaxContainerId] = useState<string | null>(null);

    const cameraZoneRenderer = (
        <ZoomablePage
            components={[
                {
                    component: (
                        <SurveillanceCamera />
                    ), position: { x: scaleToPercentage(0.3), y: scaleToPercentage(0.9) }
                }
            ]}
            showZoomControls={false}
            isImageDraggable={false}
            isCamCreationAllowed={false} />
    );

    const toggleMaximize = (containerName: string) => {
        setMaxContainerId(prev => prev === containerName ? null : containerName);
    }

    return (
        <section className="incident-feed-and-location">

            <ul className='container'>
                <li className={`item live-feed ${maxContainerId == 'live-feed' ? 'max' : ''}`}>
                    <section className="content">
                        <figure>
                            <img src={incidentImage} />
                        </figure>
                        <footer className="actions">
                            <CustomButton
                                icon={<SvgIcon name={`${maxContainerId == 'live-feed' ? 'minimize' : 'maximize'}`} height={16} />}
                                onClick={() => toggleMaximize('live-feed')} />
                        </footer>
                    </section>
                </li>
                <li className='item recording'>
                    <section className="content">
                        <figure>
                            <img src={incidentImage} />
                        </figure>
                    </section>
                </li>
            </ul>

            <ul className='container'>
                <li className='item nested'>
                    <section className="content">
                        <ol className='container inner-container'>
                            <li className={`item incident-image1 ${maxContainerId == 'incident-image1' ? 'max' : ''}`}>
                                <section className="content">
                                    <figure>
                                        <img src={incidentImage} />
                                    </figure>
                                    <footer className="actions">
                                        <CustomButton
                                            icon={<SvgIcon name={`${maxContainerId == 'incident-image1' ? 'minimize' : 'maximize'}`} height={16} />}
                                            onClick={() => toggleMaximize('incident-image1')} />
                                    </footer>
                                </section>
                            </li>
                            <li className={`item incident-image2 ${maxContainerId == 'incident-image2' ? 'max' : ''}`}>
                                <section className="content">
                                    <figure>
                                        <img src={incidentImage} />
                                    </figure>
                                    <footer className="actions">
                                        <CustomButton
                                            icon={<SvgIcon name={`${maxContainerId == 'incident-image2' ? 'minimize' : 'maximize'}`} height={16} />}
                                            onClick={() => toggleMaximize('incident-image2')} />
                                    </footer>
                                </section>
                            </li>
                        </ol>
                        <ol className='container inner-container'>
                            <li className={`item incident-image3 ${maxContainerId == 'incident-image3' ? 'max' : ''}`}>
                                <section className="content">
                                    <figure>
                                        <img src={incidentImage} />
                                    </figure>
                                    <footer className="actions">
                                        <CustomButton
                                            icon={<SvgIcon name={`${maxContainerId == 'incident-image3' ? 'minimize' : 'maximize'}`} height={16} />}
                                            onClick={() => toggleMaximize('incident-image3')} />
                                    </footer>
                                </section>
                            </li>
                            <li className={`item incident-image4 ${maxContainerId == 'incident-image4' ? 'max' : ''}`}>
                                <section className="content">
                                    <figure>
                                        <img src={incidentImage} />
                                    </figure>
                                    <footer className="actions">
                                        <CustomButton
                                            icon={<SvgIcon name={`${maxContainerId == 'incident-image4' ? 'minimize' : 'maximize'}`} height={16} />}
                                            onClick={() => toggleMaximize('incident-image4')} />
                                    </footer>
                                </section>
                            </li>
                        </ol>
                    </section>
                </li>
                <li className={`item cam-zone ${maxContainerId == 'cam-zone' ? 'max' : ''}`}>
                    <section className="content">
                        {cameraZoneRenderer}
                        <footer className="actions">
                            <CustomButton
                                icon={<SvgIcon name={`${maxContainerId == 'cam-zone' ? 'minimize' : 'maximize'}`} height={16} />}
                                onClick={() => toggleMaximize('cam-zone')} />
                        </footer>
                    </section>

                </li>
            </ul>

        </section>
    )
}

export default IncedentFeedAndLocation;