// src/components/ToastManager.ts
import { generateUUID } from "../../sdk/utils/utils";

interface Toast {
    id: string;
    message: string;
    type: 'success' | 'error' | 'info' | 'warning';
    duration: number;
}

class ToastManager {
    private toasts: Toast[] = [];
    private listeners: Function[] = [];

    addToast(message: string, type: 'success' | 'error' | 'info' | 'warning', duration = 2000) {
        const id = generateUUID(); // Generate a unique ID

        const newToast: Toast = { id, message, type, duration };
        this.toasts.push(newToast);
        this.notifyListeners();
        setTimeout(() => {
            this.removeToast(id);
        }, duration);
    }

    removeToast(id: string) {
        this.toasts = this.toasts.filter(toast => toast.id !== id);
        this.notifyListeners();
    }

    subscribe(listener: Function) {
        this.listeners.push(listener);
    }

    unsubscribe(listener: Function) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    getToasts() {
        return this.toasts;
    }

    private notifyListeners() {
        this.listeners.forEach(listener => listener(this.toasts));
    }
}

const toastManager = new ToastManager();
export default toastManager;
