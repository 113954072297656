import React, { useState, useEffect, useCallback, ForwardRefRenderFunction, useImperativeHandle, forwardRef } from 'react';
import './AddCameraDetailsComponent.scss';
// import floorPlan from '../../assets/images/floor-plan.png';
import floorPlan from '../../assets/svgs/floor-plan.svg';
import ImageWithDraggableIcon from '../ImageWithDraggableIcon/ImageWithDraggableIconComponent';
import { debounce } from '../../utils/debounce';
import ZoomableCanvas from '../zoomableCanvas/ZoomableCanvasComponent';
import CameraView from '../CameraView/CameraViewComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { percentageToScale } from '../../utils/common.util';
import cameraConfigService from '../../sdk/services/cameraConfigService';
import alertConfigService from '../../sdk/services/alertConfigService';
import { useDispatch } from 'react-redux';
import { addCamera } from '../../sdk/redux/slices/cameraSlice';

export type FormFieldsType = {
    cameraId: string;
    cameraName: string;
    rtspUrl: string;
    location: string;
    coordinateX: string;
    coordinateY: string;
    cameraAngle: number;
    fieldOfView: number;
};

interface IAddCameraDetails {
    onFormChange: (data: FormFieldsType) => void;
    onCameraAddedEvent: (data: CameraConfigType) => void;
    formValidationResultEvent: (result: boolean) => void;
}


// Define a ref type for the methods you want to expose
export interface AddCameraDetailsHandle {
    save: () => void;
}

const AddCameraDetails: ForwardRefRenderFunction<AddCameraDetailsHandle, IAddCameraDetails> = ({ onFormChange, formValidationResultEvent }, ref) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FormFieldsType>({
        cameraId: '',
        cameraName: '',
        rtspUrl: '',
        location: '',
        coordinateX: '',
        coordinateY: '',
        cameraAngle: 0,
        fieldOfView: 0,
    });

    // Debounced function for handling form change
    const debouncedOnFormChange = useCallback(
        debounce((data: FormFieldsType) => {
            try {
                onFormChange(data);
            } catch (error) {
                console.error('Error updating form:', error);
            }
        }, 500),
        [onFormChange]
    );

    // Handle changes in form fields
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => {
            const updatedValue = name === 'cameraAngle' || name === 'fieldOfView'
                ? Math.max(0, Math.min(parseInt(value, 10), 360))
                : value;
            const updatedFormData = { ...prevState, [name]: updatedValue };

            debouncedOnFormChange(updatedFormData);
            return updatedFormData;
        });
    };

    // Handle changes in icon position
    // const handleIconPositionChange = (x: number, y: number) => {
    //     setFormData(prevState => {
    //         const updatedFormData = {
    //             ...prevState,
    //             coordinateX: x.toFixed(2),
    //             coordinateY: y.toFixed(2),
    //         };
    //         debouncedOnFormChange(updatedFormData);
    //         return updatedFormData;
    //     });
    // };

    const validateForm = () => {
        const { cameraId, cameraName, rtspUrl, location, coordinateX, coordinateY, cameraAngle, fieldOfView } = formData;
        return (
            cameraId.trim() !== '' &&
            cameraName.trim() !== '' &&
            rtspUrl.trim() !== '' &&
            location.trim() !== '' &&
            coordinateX.trim() !== '' &&
            coordinateY.trim() !== '' &&
            cameraAngle.toString() !== '' &&
            fieldOfView.toString() !== '' &&
            cameraAngle >= 0 && cameraAngle <= 360 &&
            fieldOfView > 0 && fieldOfView <= 360
        );
    };

    useEffect(() => {
        formValidationResultEvent(validateForm());
    }, [formData]);

    const handleAddCamera = async (formData: FormFieldsType) => {
        try {
            const payload: CameraConfigType = {
                ...formData,
                coordinateX: percentageToScale(formData.coordinateX),
                coordinateY: percentageToScale(formData.coordinateY),
                vmsLiveFeedUrl: '',
                primaryImageUrl: ''
            };
            // const addCamera = 
            const addCameraResponse = await cameraConfigService.addCamera(payload);
            dispatch(addCamera(addCameraResponse));
            if (addCameraResponse?.id) {
                const addFixedAlerts = await alertConfigService.createFixedAlert(addCameraResponse.id)
                console.log(addFixedAlerts);
            }
            // setSelectedCamera(addCameraResponse);
        } catch (error) {
            console.error("Error adding camera:", error);
        }
    }

    useImperativeHandle(ref, () => ({
        save: () => {
            handleAddCamera(formData);
        }
    }), [formData, onFormChange]);

    // Render a single input block
    const inputBlockRenderer = (
        labelTxt: string,
        inputType: 'text' | 'number',
        isMandatory: boolean,
        placeHolderTxt: string,
        isDisabled: boolean,
        name: keyof FormFieldsType,
        min?: number,
        max?: number
    ) => (
        <div className="input-block">
            <label htmlFor={name}>
                {labelTxt}{isMandatory && <mark>*</mark>}
            </label>
            <input
                type={inputType}
                placeholder={placeHolderTxt}
                disabled={isDisabled}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                min={min}
                max={max}
            />
        </div>
    );

    // Effect to clean up any remaining debounced calls when the component unmounts
    useEffect(() => {
        return () => {
            debouncedOnFormChange.cancel();
        };
    }, [debouncedOnFormChange]);

    const initialCameraViews = [
        { x: 100, y: 150, cameraAngle: 30, cameraFieldOfView: 60, pinTxt: '1' },
        { x: 300, y: 200, cameraAngle: 45, cameraFieldOfView: 75, pinTxt: '2' },
    ];

    const handleComponentPositionChange = (position: { x: string; y: string }) => {
        console.log(`Updated/Created component position: x=${position.x}, y=${position.y}`);
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                coordinateX: position.x,
                coordinateY: position.y,
            };
            debouncedOnFormChange(updatedFormData);
            return updatedFormData;
        });
    }

    // const cameraViewRenderer = (formData: FormFieldsType) => {
    //     console.log('formData:: ', formData);
    //     return (
    //         <CameraView
    //             cameraAngle={formData.cameraAngle}
    //             fieldOfView={formData.fieldOfView}
    //             showPin={false}
    //             onPinClick={() => {
    //                 console.log(this);
    //                 // setSelectedCamView(id);
    //             }}
    //         />
    //     )
    // }

    const draggableComponent = {
        component: (
            <CameraView
                cameraAngle={formData?.cameraAngle || 0}
                fieldOfView={formData?.fieldOfView || 0}
                showPin={false}
            />
        ),
        cameraAngle: formData.cameraAngle,
        fieldOfView: formData.fieldOfView
        // ), position: { x: (selectedCamera?.coordinateX || 0)?.toString(), y: (selectedCamera?.coordinateY || 0)?.toString() }
    }


    return (
        <section className="add-camera-details">
            <aside className="left-content">
                <header>Camera details</header>

                <section className="form-block">
                    <div className="form-content">
                        {inputBlockRenderer('Camera ID', 'text', true, 'Enter camera ID', false, 'cameraId')}
                        {inputBlockRenderer('Camera Name', 'text', true, 'Enter camera name', false, 'cameraName')}
                        {inputBlockRenderer('RTSP URL', 'text', true, 'Enter RTSP URL', false, 'rtspUrl')}
                        {inputBlockRenderer('Camera Location', 'text', true, 'Enter camera location', false, 'location')}
                        <div className="row">
                            <section className="column">
                                {inputBlockRenderer('x-coordinate', 'text', true, 'x-coordinate', true, 'coordinateX')}
                            </section>
                            <section className="column">
                                {inputBlockRenderer('y-coordinate', 'text', true, 'y-coordinate', true, 'coordinateY')}
                            </section>
                        </div>
                        <p>*Click on the map for entering camera location</p>
                        <div className="row">
                            <section className="column">
                                {inputBlockRenderer('Camera Angle', 'number', true, 'Enter angle', false, 'cameraAngle', 0, 360)}
                            </section>
                            <section className="column">
                                {inputBlockRenderer('Camera FoV', 'number', true, 'Enter FoV', false, 'fieldOfView', 0, 360)}
                            </section>
                        </div>
                    </div>
                </section>
            </aside>

            <article className="main-content">
                {/* <ImageWithDraggableIcon
                    imgSrc={floorPlan}
                    cameraAngle={formData.cameraAngle}
                    cameraFieldOfView={formData.fieldOfView}
                    // onPositionChange={handleIconPositionChange}
                    onPositionChange={() => { }}
                    isDragging={true}
                /> */}
                {/* <ZoomableCanvas
                    imgSrc={floorPlan}
                    cameraAngle={formData.cameraAngle}
                    cameraFieldOfView={formData.fieldOfView}
                    onPositionChange={handleIconPositionChange}
                    camCreatable={true}
                // cameraViewsData={initialCameraViews}
                /> */}

                {/* <p>{formData?.cameraAngle}</p> */}

                <ZoomablePage
                    components={[]}
                    showZoomControls={false}
                    isImageDraggable={false}
                    isCamCreationAllowed={true}
                    draggableComponent={draggableComponent}
                    onComponentPositionChange={handleComponentPositionChange} />
            </article>
        </section>
    );
};

export default forwardRef(AddCameraDetails);
