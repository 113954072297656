import CustomCheckbox from '../CustomCheckbox/CustomCheckboxComponent';
import CustomSelect from '../CustomSelect/CustomSelectComponent';
import './ConfigActionComponent.scss';
// import floorPlanImage from '../../assets/images/floor-plan.png';
import floorPlanImage from '../../assets/svgs/floor-plan.svg';
import ImageWithDraggableIcon from '../ImageWithDraggableIcon/ImageWithDraggableIconComponent';
import floorHotspotImage from '../../assets/images/floor-hotspot1.png';
import ZoomableCanvas from '../zoomableCanvas/ZoomableCanvasComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import CameraView from '../CameraView/CameraViewComponent';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import RippleEffect from '../RippleEffect/RippleEffect';
import { useEffect, useState } from 'react';
import { AlertConfigType } from '../../sdk/types/alertConfig.type';
import alertConfigService from '../../sdk/services/alertConfigService';
import SurveillanceCamera from '../SurveillanceCamera/SurveillanceCameraComponent';
import { scaleToPercentage } from '../../utils/common.util';
import customAlertConfigService from '../../sdk/services/customAlertConfigService';
import { CustomAlertConfigType } from '../../sdk/types/customAlertConfig.type';
import CustomAlertConfig from '../CustomAlertConfig/CustomAlertConfigComponent';

const listOptions: AlertConfigType[] = [
    {
        "id": "fb1b6f0403c943dfb177586b81657ce5",
        "alertName": "PPE - No Hard Hat",
        "alertType": "required_accessories",
        "enabled": false,
        "accessories": [
            "hardhats"
        ],
        "cameraFk": "e46768fdb57d47b596be27b6a191bc83",
        "cameraId": "6",
        "cameraName": "cam_6",
        "severity": "Severity Level 1",
        "createdBy": "Admin",
        "updatedBy": "Admin",
        "createdDate": 1724472279623,
        "lastModifiedDate": 1724472279623
    },
    {
        "id": "6032a4fccf5c4e598ce7888215f9ccc8",
        "alertName": "PPE - No Safety Vest",
        "alertType": "required_accessories",
        "enabled": false,
        "accessories": [
            "vest"
        ],
        "cameraFk": "e46768fdb57d47b596be27b6a191bc83",
        "cameraId": "6",
        "cameraName": "cam_6",
        "severity": "Severity Level 1",
        "createdBy": "Admin",
        "updatedBy": "Admin",
        "createdDate": 1724472279621,
        "lastModifiedDate": 1724472279621
    }
]

interface IConfigActionProps {
    selectedCamera: CameraConfigType | null;
}

const ConfigAction: React.FC<IConfigActionProps> = ({ selectedCamera }) => {
    const [safetyConfigList, setSafetyConfigList] = useState<AlertConfigType[]>([]);

    useEffect(() => {
        console.log(selectedCamera);
        const fetchSafetyConfigList = async () => {
            try {
                if (selectedCamera?.id) {
                    const list: AlertConfigType[] = await alertConfigService.getSafetyConfigList(selectedCamera?.id);
                    // const list: CameraConfigType[] = mockCameraList;
                    // setSelectedCamera(list[0] || null);
                    setSafetyConfigList(list);
                }
            } catch (error) {

            } finally {

            }
        }
        fetchSafetyConfigList();
    }, [selectedCamera]);

    const dynamicComponents = () => {
        const { coordinateX, coordinateY } = selectedCamera || {} as CameraConfigType;
        const x = scaleToPercentage(coordinateX);
        const y = scaleToPercentage(coordinateY);
        return [
            {
                component: (
                    // <CameraView
                    //     svgIconName='surveillanceCamera'
                    //     cameraAngle={selectedCamera?.cameraAngle || 0}
                    //     fieldOfView={selectedCamera?.fieldOfView || 0}
                    //     showPin={false}
                    // />
                    <SurveillanceCamera />
                    // ), position: { x: '30%', y: '50%' }
                ), position: { x, y }
                // ), position: { x: (selectedCamera?.x_coordinate || 0)?.toString(), y: (selectedCamera?.y_coordinate || 0)?.toString() }
            },
        ]
    }

    const cameraZoneRenderer = (
        <aside className="camera-location">
            <header>Camera Location - {selectedCamera?.location}</header>
            <figure>
                {/* <ImageWithDraggableIcon
                        imgSrc={floorPlanImage}
                        cameraAngle={145}
                        cameraFieldOfView={190}
                        iconPosition={{ x: 90, y: 245 }} // Pass initial position
                        onPositionChange={(position) => console.log('New position:', position)}
                        isDragging={false}
                    /> */}
                {/* <ZoomableCanvas
                        imgSrc={floorPlanImage}
                        // cameraAngle={0}
                        // cameraFieldOfView={0}
                        // onPositionChange={handleIconPositionChange}
                        camCreatable={false}
                        cameraViewsData={[{ x: 300, y: 200, cameraAngle: 45, cameraFieldOfView: 75, pinTxt: '2' },
                        ]}
                    /> */}
                <ZoomablePage
                    components={dynamicComponents()}
                    showZoomControls={false}
                    isImageDraggable={false}
                    isCamCreationAllowed={false} />
            </figure>
        </aside>
    );

    const handleUpdateFixedAlertEnable = (enabled: boolean, config: AlertConfigType) => {
        console.log(enabled);
        alertConfigService.updateFixedAlertConfig(config.id, { enabled });
    }

    const modifyAlertRenderer = (
        <ul className="selection-list">
            {/* {
                listOptions?.map((config: any, index: number) => (
                    <li key={index}>

                        <article>
                            <CustomCheckbox checked={config?.enabled} />
                            <span>{config.alertName}</span>
                        </article>
                        <CustomSelect
                            options={config.options}
                            selectedOption={config.selected}
                            placeholder={'Select security level'}
                            onChange={(option: string): void => {
                                console.log(option)
                            }} />
                    </li>
                ))
            } */}
            {
                safetyConfigList?.map((config: AlertConfigType, index: number) => (
                    <li key={index}>
                        <article>
                            <CustomCheckbox checked={config?.enabled} onChange={(enabled) => handleUpdateFixedAlertEnable(enabled, config)} />
                            <span>{config.alertName}</span>
                        </article>
                        <CustomSelect
                            options={[config.severity]}
                            selectedOption={config.severity}
                            placeholder={'Select security level'}
                            onChange={(option: string): void => {
                                console.log(option)
                            }} />
                    </li>
                ))
            }
        </ul>
    );

    return (
        <section className="config-action">
            {
                selectedCamera?.id ? (
                    <>
                        <header className="config-action-header">
                            <label>Cam ID - {selectedCamera?.cameraId} - Configuration</label>
                        </header>

                        <article className="action-list-and-zone">
                            {modifyAlertRenderer}
                            {cameraZoneRenderer}
                        </article>

                        <CustomAlertConfig selectedCamera={selectedCamera} />
                    </>
                ) : (
                    <section className="no-camera-selected">
                        <p>No camera selected</p>
                        {/* <RippleEffect as="div">
                            <CustomButton text='Add camera' variant='contained' onClick={() => console.log('add camera')} />
                        </RippleEffect> */}
                    </section>
                )
            }
        </section >
    );
}

export default ConfigAction;