import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux';
import store from './sdk/redux/store/store';
import AppInit from './AppInit';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlayComponent';
import ToastProvider from './components/CustomToast/CustomToastProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                {/* <ToastProvider> */}
                <AuthProvider>
                    <LoadingOverlay />
                    <AppInit />
                </AuthProvider>
                {/* </ToastProvider> */}
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
