import React, { useEffect } from 'react';
import './App.scss';
import AppRoutes from './routes/AppRoutes';
import { useDispatch } from 'react-redux';
import { setUser } from './sdk/redux/slices/userSlice';
import { useToast } from './components/CustomToast/CustomToastProvider';
import ToastContainer from './components/CustomToast/ToastContainer';
import toastManager from './components/CustomToast/ToastManager';

interface IProps {
    appConfig?: any;
    userConfig?: any;
}
const App: React.FC<IProps> = ({ appConfig, userConfig }) => {
    console.log('AppConfig:: ', appConfig);
    console.log('UserConfig:: ', userConfig);
    // const { showToast } = useToast();

    useEffect(() => {
        // alert();
        // toastManager.addToast('This is a success message!', 'success');
        // toastManager.addToast('This is an error message!', 'error');
        // toastManager.addToast('This is an info message!', 'info');
        // toastManager.addToast('This is a warning message!', 'warning');
        console.log('teset');
    }, [])

    // const dispatch = useDispatch();
    // dispatch(setUser({
    //     firstName: 'Hemanth',
    //     lastName: 'Kumar',
    //     emailId: 'hemanthkumarmk19@gmail.com',
    //     designation: 'Ops Manager'
    // }))

    return (
        <AppRoutes />
    );
}

export default App;
