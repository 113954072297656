import { Notifications } from "@mui/icons-material";
import React, { useState } from "react";
import AlertBox from "../AlertBox/AlertBoxComponent";
import CustomButton from "../CustomButton/CustomButtonComponent";
import SvgIcon from "../SvgIcons/SvgIconComponent";
import './ExpandableAlertList.scss';
import { AlertDetailsType } from "../../sdk/types/alert.type";

// Define a type for the list item props
interface ListItem {
    id: number;
    title: string;
    content: React.ReactNode;
}

// Define the component props
interface ExpandableListProps {
    items: AlertDetailsType[];
    showHeader?: boolean;
    onAlertViewDetailsClick: (selectedAlert: AlertDetailsType) => void;
}

const ExpandableAlertList: React.FC<ExpandableListProps> = ({ items, onAlertViewDetailsClick, showHeader = true }) => {
    const [expandedId, setExpandedId] = useState<string | null>(null);

    const handleClick = (id: string) => {
        setExpandedId(expandedId === id ? null : id); // Toggle expansion
    };

    return (
        <section className="expandable-alert-list">

            {
                showHeader && (
                    <header className="list-header">
                        <div>
                            <h4>Alerts</h4>
                        </div>

                        <aside className="actions">
                            <CustomButton icon={<SvgIcon name='sort' width={16} height={16} />} variant='contained' />
                            <CustomButton icon={<SvgIcon name='search' width={16} height={16} />} variant='contained' />
                        </aside>
                    </header>
                )
            }

            <aside className={`list-content ${!items?.length ? 'no-alerts' : ''}`}>
                <ul>
                    {items.map((item: AlertDetailsType) => (
                        <li key={item.id} onClick={() => handleClick(item.id)} className={(expandedId == item?.id) ? 'selected' : ''}>
                            <AlertBox
                                headerLabel={item?.alertName}
                                subHeaderLabel={item?.camera?.location}
                                subHeaderIcon={<Notifications />}
                                footerLabel={item?.camera?.cameraId}
                                timestamp="07-22-2024 | 12:09 AM"
                                showActionSection={expandedId === item.id} // Show action section if this is the expanded item
                                showViewDetailsBtn={true}
                                isAlertResolved={item?.resolved}
                                // thumbnails={{
                                //     image:
                                //         "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                //     video:
                                //         "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                // }}
                                thumbnails={{
                                    image: item?.images[0] || '',
                                    video:
                                        "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                }}
                                onAlertViewDetailsClick={() => onAlertViewDetailsClick(item)}
                            />
                        </li>
                    ))}
                </ul>

                {
                    !items?.length && (
                        <section className="no-alert-block">
                            <p>No alert to display</p>
                        </section>
                    )
                }

            </aside>
        </section>
    );
};

export default ExpandableAlertList;
