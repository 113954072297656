import "./AnalyticsPage.scss";

const AnalyticsPage: React.FC = () => {
    return (
        <section className="analytics-page">
            Analytics page
        </section>
    );
}

export default AnalyticsPage;